<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalUploadDocument"
      @click:outside="executeCloseModalUploadDocument"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>Cargar Nuevo Documento</h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-form @submit.prevent="sendUploadDocument">
              <v-row align-content="center" class="mt-2">
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label class="mt-1"> Carpeta Contenedora * </label>
                  <v-autocomplete
                    @change="searchFoldersDocumentsTypes(null)"
                    placeholder="Selecciona la Unidad de Negocio"
                    v-model="$v.form.folder_id.$model"
                    :error="$v.form.folder_id.$invalid && submitUpload"
                    :error-messages="
                      $v.form.folder_id.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="foldersFromProjectOrPresale"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label class="mt-1"> Área Responsable * </label>
                  <v-autocomplete
                    placeholder="Selecciona la Unidad de Negocio"
                    v-model="$v.form.assign_area_id.$model"
                    :error="$v.form.assign_area_id.$invalid && submitUpload"
                    :error-messages="
                      $v.form.assign_area_id.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="assignedAreas"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label class="mt-1"> Nombre de Documento * </label>
                  <v-autocomplete
                    :disabled="!form.folder_id"
                    placeholder="Selecciona el Nombre de Documento"
                    v-model="$v.form.folder_document_type_id.$model"
                    :error="
                      $v.form.folder_document_type_id.$invalid && submitUpload
                    "
                    :error-messages="
                      $v.form.folder_document_type_id.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="filteredFoldersDocumentsTypes"
                    @update:search-input="searchFoldersDocumentsTypes"
                    no-data-text="Sin Nombres de Documentos Disponibles"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="document"> Documento * </label>
                  <v-file-input
                    placeholder="Selecciona el Documento"
                    v-model="$v.form.file.$model"
                    prepend-icon="mdi-paperclip"
                    :accept="acceptedFormats"
                    :error="$v.form.file.$invalid && submitUpload"
                    :error-messages="
                      $v.form.file.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="document"> Comentario </label>
                  <v-text-field
                    placeholder="Ingresa el Comentario"
                    v-model="form.comment"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalUploadDocument"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            @click="sendUploadDocument"
            color="primary"
            small
            class="float-right"
          >
            Cargar Documento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalUploadDocument: {
      type: Function,
      default: () => {},
    },
    isPresale: {
      type: Boolean,
      default: false,
    },
    isProject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acceptedFormats: [
        ".png",
        ".jpg",
        ".jpeg",
        ".bmp",
        ".txt",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".pdf",
        ".rar",
        ".zip",
      ],
      loading: false,
      loadingFolders: false,
      debounce: null,
      debounceTime: 500,
      form: {
        folder_id: null,
        assign_area_id: null,
        folder_document_type_id: null,
        comment: "",
        file: null,
      },
      submitUpload: false,
    };
  },
  validations: {
    form: {
      folder_id: { required },
      assign_area_id: { required },
      folder_document_type_id: { required },
      comment: {},
      file: { required },
    },
  },
  mounted() {
    this.getAssignedAreas();
  },
  methods: {
    ...mapActions({
      getAssignedAreas: "document/getAssignedAreas",
      uploadDocumentFromPresaleOrProject:
        "document/uploadDocumentFromPresaleOrProject",
      getAllfoldersDocumentsTypes: "document/getAllfoldersDocumentsTypes",
    }),
    async searchFoldersDocumentsTypes(search = null) {
      if (
        (!search && this.form.folder_id) ||
        ((search || typeof search == "string") &&
          !this.form.folder_document_type_id)
      ) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = await setTimeout(async function () {
          self.form.folder_document_type_id = null;
          self.loadingFolders = true;
          await self.getAllfoldersDocumentsTypes({
            folder_id: self.form.folder_id,
            filter: search ? search : undefined,
            items: 20,
          });
          self.loadingFolders = false;
        }, self.debounceTime);
      }
    },
    async sendUploadDocument() {
      let self = this;
      self.submitUpload = true;
      if (!self.$v.form.$invalid) {
        if (
          !self.acceptedFormats.includes(
            `.${self.form.file.name.split(".").pop()}`
          )
        ) {
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: "El formato del archivo no es válido",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        self.loading = true;
        const resp = await self.uploadDocumentFromPresaleOrProject({
          body: {
            created_by: self.user.email,
            comment: self.form.comment,
            project_id: self.isProject ? self.currentProject.id : undefined,
            pre_project_id: self.isPresale
              ? self.$route.query.presale_code
              : undefined,
            file_dict: {
              [self.form.file.name]: self.form.folder_document_type_id,
            },
          },
          files: [self.form.file],
        });
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: "Documento cargado correctamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("documentCreatedOrUpdated");
          this.closeModalUploadDocument(true);
          this.resetForm();
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    executeCloseModalUploadDocument($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalUploadDocument($event);
      }
    },
    resetForm() {
      this.form = {
        folder_id: null,
        assign_area_id: null,
        folder_document_type_id: null,
        comment: "",
        file: null,
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      foldersDocumentsTypes: "document/foldersDocumentsTypes",
      assignedAreas: "document/assignedAreas",
      foldersFromProjectOrPresale: "document/foldersFromProjectOrPresale",
      currentProject: "project/currentProject",
    }),
    filteredFoldersDocumentsTypes() {
      return this.foldersDocumentsTypes.items
        .map(function (item) {
          return {
            id: item.id,
            name: item.document_type.name,
            assigned_area: item.document_type.assigned_area,
          };
        })
        .filter((item) => {
          if (this.form.assign_area_id) {
            return item.assigned_area.id == this.form.assign_area_id;
          }
          return item;
        });
    },
  },
};
</script>

<style></style>
