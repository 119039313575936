var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-dialog',{attrs:{"max-width":"800px","scrollable":""},on:{"keydown":_vm.executeCloseModalUploadDocument,"click:outside":_vm.executeCloseModalUploadDocument},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v("Cargar Nuevo Documento")])]),_c('hr'),_c('v-card-text',[_c('v-container',{},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendUploadDocument.apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-2",attrs:{"align-content":"center"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{staticClass:"mt-1"},[_vm._v(" Carpeta Contenedora * ")]),_c('v-autocomplete',{attrs:{"placeholder":"Selecciona la Unidad de Negocio","error":_vm.$v.form.folder_id.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.folder_id.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.foldersFromProjectOrPresale,"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.searchFoldersDocumentsTypes(null)}},model:{value:(_vm.$v.form.folder_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.folder_id, "$model", $$v)},expression:"$v.form.folder_id.$model"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{staticClass:"mt-1"},[_vm._v(" Área Responsable * ")]),_c('v-autocomplete',{attrs:{"placeholder":"Selecciona la Unidad de Negocio","error":_vm.$v.form.assign_area_id.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.assign_area_id.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.assignedAreas,"item-text":"name","item-value":"id"},model:{value:(_vm.$v.form.assign_area_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.assign_area_id, "$model", $$v)},expression:"$v.form.assign_area_id.$model"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{staticClass:"mt-1"},[_vm._v(" Nombre de Documento * ")]),_c('v-autocomplete',{attrs:{"disabled":!_vm.form.folder_id,"placeholder":"Selecciona el Nombre de Documento","error":_vm.$v.form.folder_document_type_id.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.folder_document_type_id.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null,"items":_vm.filteredFoldersDocumentsTypes,"no-data-text":"Sin Nombres de Documentos Disponibles","item-text":"name","item-value":"id"},on:{"update:search-input":_vm.searchFoldersDocumentsTypes},model:{value:(_vm.$v.form.folder_document_type_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.folder_document_type_id, "$model", $$v)},expression:"$v.form.folder_document_type_id.$model"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{attrs:{"for":"document"}},[_vm._v(" Documento * ")]),_c('v-file-input',{attrs:{"placeholder":"Selecciona el Documento","prepend-icon":"mdi-paperclip","accept":_vm.acceptedFormats,"error":_vm.$v.form.file.$invalid && _vm.submitUpload,"error-messages":_vm.$v.form.file.$invalid && _vm.submitUpload
                      ? 'Este campo es requerido'
                      : null},model:{value:(_vm.$v.form.file.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.file, "$model", $$v)},expression:"$v.form.file.$model"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{attrs:{"for":"document"}},[_vm._v(" Comentario ")]),_c('v-text-field',{attrs:{"placeholder":"Ingresa el Comentario"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalUploadDocument}},[_c('small',[_vm._v(" Cancelar ")])]),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","small":""},on:{"click":_vm.sendUploadDocument}},[_vm._v(" Cargar Documento ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }