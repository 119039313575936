<template>
  <section class="px-16 mt-8">
    <loading-flux :value="loading"></loading-flux>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <section
          class="d-flex flex-column flex-sm-row justify-content-between align-items-center"
        >
          <h2 class="my-3">
            <v-tooltip top v-if="isPresale">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  @click="$router.go(-1)"
                  size="lg"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-arrow-left
                </v-icon>
              </template>
              <span>Volver</span>
            </v-tooltip>
            Documentos de {{ getTitleFromIsProjectOrPresale }}
            {{ getCodeFromIsProjectOrPresale }}
          </h2>
          <v-btn
            :disabled="loadingDocuments || !documents.length"
            @click="openModalUploadDocument"
            color="primary"
            :dark="false"
            class="text-white mx-16"
          >
            <i class="mdi mdi-upload me-1"></i>
            Cargar Nuevo Documento
          </v-btn>
        </section>
      </v-col>
      <v-row class="px-16 mt-5">
        <label for="" class=""> Filtros </label>
        <v-col cols="12" md="4" sm="12" class="pt-0">
          <v-select
            v-model="filters.document_type_filter"
            :items="documentsTypes"
            item-text="name"
            item-value="name"
            label="Nombre de Documento"
            clearable
            @change="($event) => getDocumentsData()"
            class="mt-2"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" sm="12" class="pt-0">
          <v-menu
            ref="menu"
            v-model="datepicker_menu_start"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_start_formatted"
                prepend-icon="mdi-calendar"
                readonly
                label="Fecha (desde)"
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearDateFilters"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.date_start"
              scrollable
              locale="es"
              :max="maxDateStart"
              @input="
                datepicker_menu_start = false;
                getDocumentsData();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" sm="12" class="pt-0">
          <v-menu
            ref="menu"
            v-model="datepicker_menu_end"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_end_formatted"
                prepend-icon="mdi-calendar"
                readonly
                label="Fecha (hasta)"
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearDateFilters"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.date_end"
              scrollable
              locale="es"
              clearable
              :min="minDateEnd"
              @input="
                datepicker_menu_end = false;
                getDocumentsData();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-col cols="12" md="11" sm="12" class="px-12">
        <div v-if="loadingDocuments" class="d-flex justify-content-center">
          <p class="text-center">Cargando Documentos... Espere por favor</p>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            class="mx-auto"
            indeterminate
          ></v-progress-circular>
        </div>

        <v-treeview
          shaped
          v-else-if="documents.length"
          :open="foldersOpened"
          v-model="documentsTree"
          :items="documents"
          item-key="id"
          open-on-click
          :hoverable="false"
        >
          <template v-slot:label="{ item, open }">
            <div class="d-flex align-items-center" v-if="item.children">
              <v-icon @click="open = !open" class="mr-1">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
              <h5 class="mt-3 mx-1" v-if="item.children">
                {{ item.name }}
              </h5>
            </div>
            <v-simple-table dense v-else class="mt-2">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left px-2">
                      <h6 class="font-weight-bold">Acción</h6>
                    </th>
                    <th class="text-left px-1">
                      <h6 class="font-weight-bold">Documento</h6>
                    </th>
                    <th class="text-center px-1">
                      <h6 class="font-weight-bold">Área Responsable</h6>
                    </th>
                    <th class="text-center px-1">
                      <h6 class="font-weight-bold">Nombre de Documento</h6>
                    </th>
                    <th class="text-center px-1">
                      <h6 class="font-weight-bold">Comentario</h6>
                    </th>
                    <th class="text-center px-1">
                      <h6 class="font-weight-bold">Fecha Subida</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="document in item.documents" :key="document.id">
                    <td class="text-center">
                      <v-icon
                        @click="sendDeleteDocument(document)"
                        class="float-left"
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                    <td>
                      <div class="text-left">
                        <v-btn icon :href="document.url" target="_blank">
                          <v-icon class="mx-3">
                            {{
                              getIconMaterialFromExtension(document.extension)
                            }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </td>
                    <td>
                      <div class="text-center">
                        <v-chip
                          label
                          small
                          ripple
                          color="teal accent-4"
                          class="text-white"
                        >
                          {{ document.document_type.assigned_area.name }}
                        </v-chip>
                      </div>
                    </td>
                    <td>
                      <p class="my-0 mx-2">
                        {{ document.document_type.name }}
                      </p>
                    </td>
                    <td>
                      <p class="my-0 mx-0">
                        {{ document.comment }}
                      </p>
                    </td>
                    <td>
                      <p class="my-0 mx-0">
                        {{ formatDate(document.created_at) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-treeview>
        <div v-else class="text-center mt-16">
          <v-icon size="50" color="warning">mdi-alert-circle</v-icon>
          <h5 class="mt-5">No hay Documentos para Mostrar</h5>
        </div>
      </v-col>
    </v-row>
    <modal-upload-document
      :modalAction="dialogUploadDocument"
      :closeModalUploadDocument="closeModalUploadDocument"
      :isProject="isProject"
      :isPresale="isPresale"
      @documentCreatedOrUpdated="getDocumentsData"
    >
    </modal-upload-document>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getIconMaterialFromExtension } from "@/helpers/document";
import ModalUploadDocument from "./components/ModalUploadDocument.vue";
import { formatDateMomentToHuman } from "@/helpers/common";
import { authUsuarioComputed } from "@/state/helpers";
import moment from "moment";
export default {
  components: { ModalUploadDocument },
  data() {
    return {
      moment,
      dialogUploadDocument: false,
      documentsTree: [],
      foldersOpened: [],
      datepicker_menu_start: false,
      datepicker_menu_end: false,
      filters: {
        document_type_filter: null,
        date_start: null,
        date_end: null,
      },
      loading: false,
      loadingDocuments: false,
    };
  },
  props: {
    isProject: {
      type: Boolean,
      default: false,
    },
    isPresale: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getDocumentsData();
  },
  methods: {
    ...mapActions({
      getDocuments: "document/getDocuments",
      deleteDocument: "document/deleteDocument",
    }),
    formatDate(date) {
      return formatDateMomentToHuman(date);
    },
    clearDateFilters() {
      this.filters.date_start = null;
      this.filters.date_end = null;
      this.getDocumentsData();
    },
    async getDocumentsData() {
      this.loadingDocuments = true;
      await this.getDocuments({
        project_code: this.isProject
          ? this.$route.params.codigoProyecto
          : undefined,
        pre_project_code: this.isPresale
          ? this.$route.params.codePresale
          : undefined,
        document_type_filter: this.filters.document_type_filter
          ? this.filters.document_type_filter
          : undefined,
        since: this.filters.date_start
          ? `${this.filters.date_start}`
          : undefined,
        until: this.filters.date_end ? `${this.filters.date_end}` : undefined,
      });
      this.loadingDocuments = false;
    },
    getIconMaterialFromExtension(extension) {
      return getIconMaterialFromExtension(extension);
    },
    openModalUploadDocument() {
      this.dialogUploadDocument = true;
    },
    closeModalUploadDocument() {
      this.dialogUploadDocument = false;
    },
    sendDeleteDocument(document) {
      let self = this;
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de eliminar este documento: <b>${document.name}</b> ?`,
          text: "",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "red",
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.value) {
            this.loading = true;
            const response = await this.deleteDocument({
              document_id: document.id,
              user: self.user.email,
            });
            if (response.status == 202 || response.status == 200) {
              this.$swal.fire({
                icon: "success",
                title: "Documento eliminado correctamente!!!",
                text: "",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 5000,
                timerProgressBar: true,
              });
              this.getDocumentsData();
            }
            if (!response.status || response.status === 400) {
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: response.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 10000,
                timerProgressBar: true,
              });
            }
            this.loading = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      documents: "document/documents",
      documentsTypes: "document/documentsTypes",
    }),
    getTitleFromIsProjectOrPresale() {
      if (this.isProject) {
        return "Proyecto";
      }
      if (this.isPresale) {
        return "Preventa";
      }
      return "-";
    },
    getCodeFromIsProjectOrPresale() {
      if (this.isProject) {
        return this.$route.params.codigoProyecto;
      }
      if (this.isPresale) {
        return this.$route.params.codePresale;
      }
      return "-";
    },
    date_start_formatted() {
      if (this.filters.date_start) {
        return moment(this.filters.date_start).format("DD/MM/YYYY");
      }
      return null;
    },
    date_end_formatted() {
      if (this.filters.date_end) {
        return moment(this.filters.date_end).format("DD/MM/YYYY");
      }
      return null;
    },
    minDateEnd() {
      if (this.filters.date_start) {
        return this.filters.date_start;
      }
      return null;
    },
    maxDateStart() {
      if (this.filters.date_end) {
        return this.filters.date_end;
      }
      return null;
    },
  },
};
</script>

<style></style>
